// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
 
const firebaseConfig = {
  apiKey: "AIzaSyCk_Gsjr_5LAsvMUKYJDgeqDFTF97Hy2I8",
  authDomain: "blendtwo-1bd79.firebaseapp.com",
  projectId: "blendtwo-1bd79",
  storageBucket: "blendtwo-1bd79.appspot.com",
  messagingSenderId: "991564178931",
  appId: "1:991564178931:web:8e90d135cc3ad842265e86",
  measurementId: "G-Q9LDHWEE0F",
};
// Initialize Firebase

export const app = initializeApp(firebaseConfig);
